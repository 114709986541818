import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GenericResponse} from '../interfaces/generic-response';
import {InvestmentProject, InvestmentProjectDetail, InvestmentProjectList, PatchInvestment} from '../interfaces/investment-project';
import {Operation } from 'fast-json-patch';

@Injectable({
  providedIn: 'root'
})
export class InvestmentProjectService {
  private apiURL: string = environment.apiURL + 'InvestmentProject';
  constructor(private http: HttpClient) { }
  GetInvestmentProject(): Observable<GenericResponse<InvestmentProjectList[]>>{
    return this.http.get<GenericResponse<InvestmentProjectList[]>>(this.apiURL);
  }
  GetInvestmentProjectDetail(id: number): Observable<GenericResponse<InvestmentProjectDetail>>{
    return this.http.get<GenericResponse<InvestmentProjectDetail>>(this.apiURL + '/GetInvestmentProject?Id=' + id);
  }
  PostInvestmentProject(investmentProject: InvestmentProject): Observable<GenericResponse<any>>{
    const formData = new FormData();
    formData.append('Title', investmentProject.title);
    formData.append('Description', investmentProject.description);
    formData.append('Goal', investmentProject.goal.toString());
    formData.append('GoalMin', investmentProject.goalMin.toString());
    formData.append('ReturnOfInvestment', investmentProject.returnOfInvestment.toString());
    formData.append('RemainingTerm', investmentProject.remainingTerm.toString());
    formData.append('StartLine', investmentProject.startLine.toLocaleDateString());
    formData.append('DeadLine', investmentProject.deadLine.toString());
    formData.append('InvestmentType', investmentProject.investmentType);
    formData.append('VAN', investmentProject.van.toString());
    formData.append('TIR', investmentProject.tir.toString());
    formData.append('Latitude', investmentProject.latitude);
    formData.append('Longitude', investmentProject.longitude);
    formData.append('TotalInvestmentAmount', investmentProject.totalInvestmentAmount.toString());
    let index = 0;
    for (const pair of investmentProject.investmentCapitalStructure){
      formData.append('InvestmentCapitalStructure[' + index + '].Id', '0');
      formData.append('InvestmentCapitalStructure[' + index + '].Type', pair.type);
      formData.append('InvestmentCapitalStructure[' + index + '].Source', pair.source);
      formData.append('InvestmentCapitalStructure[' + index + '].PercentageOfTotal', pair.percentageOfTotal.toString());
      index++;
    }
    // tslint:disable-next-line:no-shadowed-variable
    investmentProject.Imgs.forEach( element => {
      formData.append('Imgs', element);
    });
    return this.http.post<GenericResponse<any>>(this.apiURL, formData);
  }
  PatchInvestmenProject(investmentProjectId: number, operations: Operation[]): Observable<GenericResponse<any>>{
    return  this.http.patch<GenericResponse<any>>(`${this.apiURL}/${investmentProjectId.toString()}`, operations);
  }
}
