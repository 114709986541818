import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';

import { BreadcrumbModule } from 'angular-crumbs';
import { SlickCarouselModule } from 'ngx-slick-carousel';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { SeguridadModule } from './seguridad/seguridad.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './interceptors/interceptor.service';
import { ConfirmDialogModule } from './confirm-dialog/confirm-dialog.module';
import { ConfirmDialogService } from './confirm-dialog/confirm-dialog.service';
import { InmueblesService } from './services/inmuebles.service';
import {InvestmentProjectService} from './services/investment-project.service';
import {InvestmentGalleryService} from './services/investment-gallery.service';
import {InvestmentCapitalStructureService} from './services/investment-capital-structure.service';
import {AgencyService} from './services/agency.service';




@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent

  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    NgbModule,
    SlickCarouselModule,
    SeguridadModule,
    HttpClientModule,
    ConfirmDialogModule
  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  }, ConfirmDialogService, InmueblesService, InvestmentProjectService,
    InvestmentGalleryService,
    InvestmentCapitalStructureService,
    AgencyService,
    { provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
