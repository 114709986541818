import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { EsAdminGuard } from './es-admin.guard';

const routes: Routes = [
  // Hompages
  { path: '', loadChildren: () => import('./components/pages/homefour/homefour.module').then(m => m.HomefourModule), data: { breadcrumb: 'Homepage' } },
  // Pages
  { path: 'about', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: 'Nosotros' } },
  { path: 'contact', loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'Contactanos' } },
  { path: 'investment-projects', loadChildren: () => import('./components/pages/investmentproject/investmentproject.module').then(m => m.InvestmentprojectsModule), data: { breadcrumb: 'Invierte con Nosotros' } },
  { path: 'login', loadChildren: () => import('./components/pages/login/login.module').then(m => m.LoginModule), data: { breadcrumb: 'Iniciar' }},
  { path: 'forgotpassword', loadChildren: () => import('./components/pages/forgotpassword/forgotpassword.module').then(m => m.ForgotPasswordModule), data: { breadcrumb: 'Recuperar' }},
  { path: 'register', loadChildren: () => import('./components/pages/register/register.module').then(m => m.RegisterModule), data: { breadcrumb: 'Registrar' } },
  // Listings
  { path: 'listing/cat/:catId', loadChildren: () => import('./components/pages/listinggrid/listinggrid.module').then(m => m.ListinggridModule), data: { breadcrumb: 'Lista' } },
  { path: 'listing-grid', loadChildren: () => import('./components/pages/listinggrid/listinggrid.module').then(m => m.ListinggridModule), data: { breadcrumb: 'Lista' } },
  { path: 'listing-details-v1/:id', loadChildren: () => import('./components/pages/listingdetail/listingdetail.module').then(m => m.ListingdetailModule), data: { breadcrumb: 'Listing Details' } },
  { path: 'project-details/:id', loadChildren: () => import('./components/pages/projectdetail/projectdetail.module').then(m => m.ProjectdetailModule), data: { breadcrumb: 'Detalle de Proyecto de Inversion' } },
  {path: 'edit-inmueble/:id', loadChildren: () => import('./components/pages/editinmueble/editinmueble.module').then(m => m.EditinmuebleModule), data: {Breadcrumb: 'Editar Inmueble'}, canActivate: [AuthGuard]},
  { path: 'submit-listing', loadChildren: () => import('./components/pages/submitlisting/submitlisting.module').then(m => m.SubmitlistingModule), data: { breadcrumb: 'Agregar' }, canActivate: [AuthGuard] },
  { path: 'profile-listings-investment', loadChildren: () => import('./components/pages/profile-listing-investment/profile-listings-investment.module').then(m => m.ProfileListingsInvestmentModule), data: { breadcrumb: 'Investment' }, canActivate: [AuthGuard, EsAdminGuard] },
  { path: 'profile-listings-investment-edit/:id', loadChildren: () => import('./components/pages/profile-listing-investment-edit/profile-listings-investment-edit.module').then(m => m.ProfileListingsInvestmentEditModule), data: { breadcrumb: 'Edit Investment' }, canActivate: [AuthGuard, EsAdminGuard] },
  { path: 'listings-investment', loadChildren: () => import('./components/pages/profilelistinginvestment/profilelistinginvestment.module').then(m => m.ProfilelistinginvestmentModule), data: { breadcrumb: 'List Investment' }, canActivate: [AuthGuard, EsAdminGuard] },
  // Agents
  { path: 'profile', loadChildren: () => import('./components/pages/profile/profile.module').then(m => m.ProfileModule), data: { breadcrumb: 'Perfil' }, canActivate: [AuthGuard] },
  { path: 'profile-listings', loadChildren: () => import('./components/pages/profilelisting/profilelisting.module').then(m => m.ProfilelistingModule), data: { breadcrumb: 'Mis Inmuebles' }, canActivate: [AuthGuard] },
  // Agency
  { path: 'agency-archive', loadChildren: () => import('./components/pages/agencyarchive/agencyarchive.module').then(m => m.AgencyarchiveModule), data: { breadcrumb: 'Archivo de agencias' } },
  { path: 'agency-details/:id', loadChildren: () => import('./components/pages/agencydetails/agencydetails.module').then(m => m.AgencydetailsModule), data: { breadcrumb: 'Detalle de agencia' } },
{path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
