import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GenericResponse} from '../interfaces/generic-response';
import { ModifyCapitalStructure} from '../interfaces/capital-structure';

@Injectable({
  providedIn: 'root'
})
export class InvestmentCapitalStructureService {
  private apiURL: string = environment.apiURL + 'CapitalStructure';
  constructor(private http: HttpClient) { }
  PutCapitalStructure(capitalStructureId: number, capitalStructure: ModifyCapitalStructure[]): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${this.apiURL}/${capitalStructureId.toString()}`, capitalStructure);
  }
}
