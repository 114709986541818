import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GenericResponse} from '../interfaces/generic-response';
import {InvestmentGallery, ModifyInvestmentGallery} from '../interfaces/investment-gallery';

@Injectable({
  providedIn: 'root'
})
export class InvestmentGalleryService {
  private apiURL: string = environment.apiURL + 'InvestmentGallery';
  constructor(private http: HttpClient) { }
  GetAllImage(id: number): Observable<GenericResponse<InvestmentGallery[]>>{
    return this.http.get<GenericResponse<InvestmentGallery[]>>(this.apiURL + '/' + id);
  }
  PutImagen(investmentProjectId: number, imgs: File[]): Observable<GenericResponse<any>>{
    const formData = new FormData();
    imgs.forEach( element => {
      formData.append('File', element);
    });
    return this.http.post<GenericResponse<any>>(this.apiURL + '/' + investmentProjectId.toString(), formData);
  }
}
