import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GenericResponse} from '../interfaces/generic-response';
import {Agency} from '../interfaces/agency';
import {user} from '../interfaces/userInterfaces';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {
  private apiURL: string = environment.apiURL + 'Agency';
  constructor(private http: HttpClient) { }
  GetAllAgency(): Observable<GenericResponse<Agency[]>>{
    return this.http.get<GenericResponse<Agency[]>>(this.apiURL);
  }
  GetAgencyById(id: number): Observable<GenericResponse<Agency>>{
    return this.http.get<GenericResponse<Agency>>(this.apiURL + '/' + id.toString());
  }
  GetAllUsersByAgencyId(id: number): Observable<GenericResponse<user[]>>{
    return this.http.get <GenericResponse<user[]>>(this.apiURL + '/GetAllUsersByAgencyId/' + id.toString());
  }
}
