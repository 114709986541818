import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RespTipoInmueble } from '../interfaces/tipoInmueble';
import { Observable } from 'rxjs';
import { RespGetContratos, RespResumInmueble, RespGetDepartamentos, ResumInmueble, RespDetalleInmueble, filtroInmueble, departments, LstProvincias, LstZonas, LstCaracteristicasTecnicasTipoInmueble, RespEstandart, RespubicacionInmueble, RespPartDetalleInmueble, RespCaracteristicasEnviadas, LstImagenes } from '../interfaces/resumInmuebles';
import { PublishInmueble } from '../components/pages/submitlisting/content/content.component';
import { SeguridadService } from '../seguridad/seguridad.service';
import { ModificarInformacionBasicaInmueblesDto, PutModificarDireccionDto, ImagenesDto, putDetalleInmueble, putDtoDetalleInmueble } from '../interfaces/utilidades';



@Injectable({
  providedIn: 'root'
})
export class InmueblesService {

  constructor(private http:HttpClient, private seguridadService:SeguridadService) {
    if(localStorage.getItem('historialInmueble')!){
      this._historialInmueble=JSON.parse(localStorage.getItem('historialInmueble')!);
    }
  }

    private apiURL:String=environment.apiURL+'Inmueble';
    private _historialInmueble:ResumInmueble[]=[];
    get historialInmueble():ResumInmueble[]{
      return [...this._historialInmueble]
    }
    addHistorialInmueble(InmuebleResumen:ResumInmueble):void{
      if(!this._historialInmueble.find(x=>x.idInmueble===InmuebleResumen.idInmueble)){
        this._historialInmueble.unshift(InmuebleResumen);
        this._historialInmueble=this._historialInmueble.splice(0,4);
        localStorage.setItem('historialInmueble',JSON.stringify(this.historialInmueble));
      }
    }



    getTipoInmueble():Observable<RespTipoInmueble>{
      return this.http.get<RespTipoInmueble>(`${this.apiURL+'/tipoinmueble'}`);
    }
    getInmueble(filtro:filtroInmueble):Observable<RespResumInmueble>{
      let params=new HttpParams();
      if(filtro.cantBanos!==undefined){
        params=params.append('cantBanos',filtro.cantBanos.toString());
      }
      if(filtro.cantDormitorio!==undefined){params=params.append('cantDormitorio',filtro.cantDormitorio.toString());}
      if(filtro.idZona!==undefined){params=params.append('idZona',filtro.idZona.toString());}
      if(filtro.idTipoInmueble!==undefined){params=params.append('idTipoInmueble',filtro.idTipoInmueble.toString());}
      if(filtro.contrato!==undefined){ params=params.append('contrato',filtro.contrato.toString());}
      return this.http.get<RespResumInmueble>(`${this.apiURL}`,{params});
    }
    getContrato():Observable<RespGetContratos>{
      return this.http.get<RespGetContratos>(`${this.apiURL+'/tipocontrato'}`);
    }
    getDepartamentos():Observable<RespGetDepartamentos>{
      return this.http.get<RespGetDepartamentos>(`${this.apiURL+'/departamentos'}`);
    }
    detalleInmueble(idInmueble:number):Observable<RespDetalleInmueble>{
      let params=new HttpParams();
      params=params.append('id',idInmueble.toString());
      return this.http.get<RespDetalleInmueble>(`${this.apiURL+'/getInmueble'}`,{params});
    }
    getProvincia(id:number):Observable<LstProvincias>{
      let params=new HttpParams();
      params=params.append('idDepartamento',id.toString());
      return this.http.get<LstProvincias>(`${this.apiURL+'/provincias'}`,{params});
    }
    getZona(id:number):Observable<LstZonas>{
      let params=new HttpParams();
      params=params.append('idProvincia',id.toString());
      return this.http.get<LstZonas>(`${this.apiURL+'/zonas'}`,{params});
    }
    getCaracteristicasTecnicasPorTipoInmueble(id:number):Observable<LstCaracteristicasTecnicasTipoInmueble>{
      let params=new HttpParams();
      params=params.append('idTipoInmueble',id.toString());
      return this.http.get<LstCaracteristicasTecnicasTipoInmueble>(`${this.apiURL+'/fichatecnica'}`,{params});
    }
    crearInmueble(inmueble:PublishInmueble):Observable<RespEstandart>{
     const formData= this.construirFormData(inmueble);

     return this.http.post<RespEstandart>(`${this.apiURL+'/CrearInmueble'}`,formData);
    }
    private construirFormData(inmueble:PublishInmueble):FormData{
      let formData=new FormData();
      formData.append('archAdj',inmueble.archAdj);
      formData.append('detalle',inmueble.detalle);
      formData.append('direccionCompleta',inmueble.direccionCompleta);
      formData.append('idContrato',inmueble.idContrato);
      formData.append('idTipoInmueble',inmueble.idTipoInmueble.toString());
      formData.append('idUsuario',inmueble.idUsuario.toString());
      formData.append('titulo',inmueble.titulo);
      formData.append('precio',inmueble.precio.toString());
      formData.append('idZona',inmueble.idZona.toString());
      formData.append('ubicacion',inmueble.ubicacion);
      formData.append('video',inmueble.video);
      formData.append('direccion',inmueble.direccionCompleta);
      inmueble.lstImagenes.forEach(element=>{
      formData.append('file',element );
      });

      var index = 0;
for(var pair of inmueble.lstFichaTecnicaInmueble){
    formData.append("lstFichaTecnicaInmueble[" + index + "].idFichaTecnica", pair.idFichaTecnica.toString());
    formData.append("lstFichaTecnicaInmueble[" + index + "].valor", pair.valor);
    index++;
  }

      return formData;
  }
  getAllInmuebleByUserId():Observable<RespResumInmueble>{
    let params=new HttpParams();
    params=params.append('id',this.seguridadService.getUserId().toString());
    return this.http.get<RespResumInmueble>(`${this.apiURL+'/miInmuebles'}`,{params});
  }
  viewInmuebleUbicacion(idInmueble:number):Observable<RespubicacionInmueble>{
    let params=new HttpParams();
    params=params.append('id',idInmueble.toString());
    return this.http.get<RespubicacionInmueble>(`${this.apiURL+'/viewInmuebleUbicacion'}`,{params});
  }
  viewRespPartDetalleInmueble(idInmueble:number):Observable<RespPartDetalleInmueble>{
    let params=new HttpParams();
    params=params.append('id',idInmueble.toString());
    return this.http.get<RespPartDetalleInmueble>(`${this.apiURL+'/viewDetalleInmueble'}`,{params});
  }
  viewCaracteristicasInmueble(idInmueble:number):Observable<RespCaracteristicasEnviadas>{
    let params=new HttpParams();
    params=params.append('id',idInmueble.toString());
    return this.http.get<RespCaracteristicasEnviadas>(`${this.apiURL+'/viewCaracteristicasInmueble'}`,{params});
  }
  viewLstImagenes(idInmueble:number):Observable<LstImagenes>{
    let params=new HttpParams();
    params=params.append('id',idInmueble.toString());
    return this.http.get<LstImagenes>(`${this.apiURL+'/getImagenesInmueble'}`,{params});
  }
  PutDireccionInmueble(idInmueble:number, idZona:number, ubicacion:string):Observable<RespEstandart>{
    const PutModificarDireccionDto:PutModificarDireccionDto={
      id:idInmueble,
      id_zona:idZona,
      ubicacion:ubicacion
    }
    return this.http.put<RespEstandart>(`${this.apiURL+'/ModificarUbicacion'}`,PutModificarDireccionDto);
  }
  PutDetallesInmueble(detalles:ModificarInformacionBasicaInmueblesDto):Observable<RespEstandart>{
    return this.http.put<RespEstandart>(`${this.apiURL+'/ModificarInformacionBasicaInmuebles'}`,detalles);
  }
  PutImagenesInmueble(idInmueble:number, Files:ImagenesDto[]):Observable<RespEstandart>{
    const formData:FormData=this.construirFormDataImagenes(idInmueble,Files);
    return this.http.put<RespEstandart>(`${this.apiURL+'/ModificarImagenesInmueble'}`,formData);
  }
  private construirFormDataImagenes(id:number,Files:ImagenesDto[]):FormData{
    let formData=new FormData();
    if(id===0){
      formData.append('idInmueble',null);
    }else{
    formData.append('idInmueble',id.toString());
    }


    var index = 0;
for(var pair of Files){
  formData.append("lstImagnes[" + index + "].Id",pair.id.toString()  );
  formData.append("lstImagnes[" + index + "].file", pair.file);
  index++;
}

    return formData;
}
putDetalleInmuebleFunc(putDetalleInmueble:putDtoDetalleInmueble):Observable<RespEstandart>{
  const formData:FormData=this.construirFormDataDetalles(putDetalleInmueble);
  return this.http.put<RespEstandart>(`${this.apiURL+'/ModificarDetalleInmueble'}`,formData);
}
private construirFormDataDetalles(dto:putDtoDetalleInmueble):FormData{
  let formData=new FormData();
  formData.append('idInmueble',dto.idInmueble.toString());


  var index = 0;
for(var pair of dto.TodosLosDetalles){
formData.append("TodosLosDetalles[" + index + "].idFichaTecnicaInmueble",pair.idFichaTecnicaInmueble.toString()  );
formData.append("TodosLosDetalles[" + index + "].idFichaTecnica", pair.idFichaTecnica.toString());
formData.append("TodosLosDetalles[" + index + "].valor", pair.valor);
index++;
}

  return formData;
}
 eliminarInmueble(id:number):Observable<RespEstandart>{
  return this.http.put<RespEstandart>(`${this.apiURL+'/delete'}`,id);
}
ocultarInmueble(id:number):Observable<RespEstandart>{
  return this.http.put<RespEstandart>(`${this.apiURL+'/hidden'}`,id);
}
}
