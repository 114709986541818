
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SeguridadService } from '../seguridad/seguridad.service';
import { spinnerService } from '../services/spinner.service';
import {finalize} from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  // tslint:disable-next-line:no-shadowed-variable
  constructor(private SeguridadService: SeguridadService, private spinnerService: spinnerService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.SeguridadService.obtenerToken();
    this.spinnerService.show();
    if (token){
      req = req.clone({
        setHeaders: {Authorization: `Bearer ${token}`}
      });
    }
    return next.handle(req).pipe(
      finalize(() => this.spinnerService.hide()));

  }
}
