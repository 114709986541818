import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CrearUser, credencialesUsuario, respuestaAutenticacion } from './seguridad';
import {RespEstandart} from "../interfaces/resumInmuebles";

@Injectable({
  providedIn: 'root'
})
export class SeguridadService {

  constructor(private httpClient: HttpClient) { }
  apiURL = environment.apiURL;
 private readonly llaveToken = 'token';
 private readonly campoRol = 'rol';



  estaLogueado():boolean{
    const token = localStorage.getItem(this.llaveToken);
    if (!token){
      return false;
    }
    return true;
  }
  logout(){
    localStorage.removeItem(this.llaveToken);
    //localStorage.removeItem(this.llaveExpiracion);
  }
  obtenerRol(): string{
    return this.obtenerCampoJWT(this.campoRol);
  }
  login(credenciales: credencialesUsuario): Observable<respuestaAutenticacion>{
    return this.httpClient.post<respuestaAutenticacion>(this.apiURL + 'Login/Autentificar', credenciales);
  }
  guardarToken(respuestaAutenticacion: respuestaAutenticacion){
    localStorage.setItem(this.llaveToken, respuestaAutenticacion.data['token']);
    //localStorage.setItem(this.llaveExpiracion, respuestaAutenticacion.expiracion.toString());
  }
  registrar(credenciales: CrearUser): Observable<respuestaAutenticacion>{
    return this.httpClient.post<respuestaAutenticacion>(this.apiURL + 'Login/CrearUsuario', credenciales);
  }
  obtenerCampoJWT(campo: string): string{
    const token = localStorage.getItem(this.llaveToken);
    if (!token){return '';}
    var dataToken = JSON.parse(atob(token.split('.')[1]));
    return dataToken[campo];
  }
  obtenerToken(){
    return localStorage.getItem(this.llaveToken);
  }
  getUserId(): number{
      return Number( this.obtenerCampoJWT('nameid'));
  }

  ForgotPassword(email: string): Observable <RespEstandart> {
    return this.httpClient.post<RespEstandart>(this.apiURL + 'Login/ResetPassword?email=' + email, null);
  }
}
